import React, { useEffect, useState } from "react"
import style from "./wash_options.module.css"
import { Form, message, Spin, Button, InputNumber, Select } from "antd"
import Head from "../../../components/Yard/header/Header"
import Header from "../../../components/Yard/common/Header"
import Arrow from "../../../assets/svg/down_arrow_icon.svg"
import Additional from "../../../components/Additional/Additional"
import CarWashOptionsSmall from "../../../components/CarWashOptionSmall/CarWashOptionsSmall"
import DateTime from "../../../components/Yard/dateTime/DateTime"
import CustomButton from "../../../components/Yard/Button/Button"
import moment from "moment"
import { navigate } from "gatsby"
import api from "../../../api"
import { getIndustryCode } from "../../../utils/helper"
const { getRailService, inspectFinish } = api
const WashOptions = props => {
  const [form] = Form.useForm()
  const [state, setState] = useState(null)
  const [newData, setNewData] = useState(null)
  const [shows, setShows] = useState(false)
  const [longs, setLongs] = useState(1)
  const [service, setService] = useState([])
  const [ser, setSer] = useState()
  const [openInput, setOpenInput] = useState([])

  const [date, setDate] = useState(
    moment(new Date())?.format("YYYY-MM-DD")?.toString()
  )
  const [time, setTime] = useState(moment()?.format("HH:mm"))
  const accordionHandlers = item => {
    setLongs(item?.id)
    item?.id === longs ? setShows(!shows) : setShows(true)
  }
  useEffect(() => {
    setState(props?.location?.state?.data)
    setNewData(props?.location?.state?.newData)
  }, [])
  const getServicee = async () => {
    try {
      const res = await getRailService()
      setService(res?.data?.data)
    } catch (e) {}
  }
  useEffect(() => {
    getServicee()
  }, [])

  useEffect(() => {
    /** const services =
      props?.location?.state?.data?.asset_txns_details?.asset_txns_details
    const filteredData = service
      ?.filter(item => {
        return services?.some(
          s => s?.field_name == item["service_name.param_description"]
        )
      })
      ?.map(s => {
        return s?.id
      }) */
    const filteredData = state?.sub_tickets?.map(item => item?.service_mst_id)

    setOpenInput(filteredData)
  }, [state])
  const callback = data => {
    setSer(data)
  }
  const dataSe = [
    {
      id: 1,
      title: "Wash Type",
      description: (
        <CarWashOptionsSmall
          service={service}
          openInput={openInput}
          setOpenInput={setOpenInput}
          data={props?.location?.state?.data}
          form={form}
          callback={callback}
        />
      ),
    },
    {
      id: 2,
      title: "Additional Services",
      description: (
        <Additional
          sendData={
            props?.location?.state?.data?.asset_txns_details?.asset_txns_details
          }
          data={props?.location?.state?.data}
          form={form}
        />
      ),
    },
  ]

  const onFinish = async type => {
    //const s = []
    /*ser &&
      ser.map((val, index) => {
        s.push({
          id: val.id,
          service_id: val.service_id,
          price: val.price,
          tax: val.tax,
          sub_service_id: val.sub_service_id,
        })
      })**/
    const s = service?.filter(item => openInput?.includes(item?.id))

    newData.services = s
    if (openInput?.length == 0) {
      return message.error("Please select at least one service")
    }
    try {
      const res = await inspectFinish({
        ...newData,
        industry_type: getIndustryCode(),
      })
      if (type === "approval" && res.data.success === true) {
        navigate("/yard/home")
      } else if (type === "begin" && res.data.success === true) {
        localStorage.setItem(
          "startTime",
          props?.location?.state?.d?.getTime()?.toString()
        )
        navigate(`/yard/wash_details/${props?.location?.state?.id}`, {
          state: {
            item: props?.location?.state?.d?.getTime()?.toString(),
            services: s,
          },
        })
      }
    } catch (e) {
      message.error({
        content: `Not able to perform the action`,
        duration: 2,
      })
    }
  }
  const onFinishFailedCarOptions = () => {}
  return (
    <>
      <Head />
      <section className={style.wash_option_section}>
        <Header
          headName="Wash Options"
          LinkName={`/yard/equipment/${props?.location?.state?.id}`}
        />
        <div className={style.wash_option_container}>
          <Form
            className="car_drop_form"
            name="basic"
            onFinish={() => onFinish("begin")}
            onFinishFailed={onFinishFailedCarOptions}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <div className="ticket_details_accordionyrd">
              <div className="contact_section">
                <div className="contact_container">
                  {dataSe.map((item, index) => {
                    return (
                      <>
                        <div
                          style={{
                            boxShadow: shows && item.id === longs ? "none" : "",
                          }}
                          key={index}
                          className={
                            shows && item.id === longs
                              ? "contact_accordion_active"
                              : "contact_accordion"
                          }
                        >
                          <div
                            className="contact_accordion_headtr"
                            onClick={() => accordionHandlers(item)}
                            style={{
                              height:
                                shows && item.id === longs ? "7vh" : "10vh",
                              borderBottom:
                                shows && item.id === longs
                                  ? "1px solid #8B8FAE"
                                  : "",
                            }}
                          >
                            <h3>{item.title}</h3>
                            <img
                              src={Arrow}
                              alt="icon forword"
                              style={{
                                transform:
                                  shows && item.id === longs
                                    ? "rotate(180deg)"
                                    : "",
                              }}
                            />
                          </div>
                          <div
                            className={
                              shows && item.id === longs
                                ? "contact_accordion_desc_active"
                                : "contact_accordion_desc"
                            }
                          >
                            {item.description}
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
            <DateTime
              label_1="date"
              label_2="time"
              date={date}
              time={time}
              setTime={setTime}
              setDate={setDate}
              width="340px"
            />
            <div className="inspection_begin_content">
              <CustomButton
                className="inspection_begin_1"
                name="Need Approval?"
                onClick={() => onFinish("approval")}
              />

              {/* <CustomButton
              className="inspection_begin_2"
              name="Begin Wash"
              onClick={() => onFinish("begin")}
            /> */}
              <Form.Item
                className="inspection_begin_2"
                style={{ marginTop: "20px" }}
              >
                <Button
                  htmlType="submit"
                  style={{
                    color: "#fff",
                    background: "#38B6FF",
                    borderradius: "7px",
                  }}
                >
                  Begin Wash
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </section>
    </>
  )
}
export default WashOptions
